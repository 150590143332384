import { router } from "@inertiajs/react";
import React from "react";
import { useErrorBoundary } from "react-error-boundary";

import { Icon } from "@/components/Icon";
import { Button } from "@/components/ui";

export default function ErrorPage({ status }: { status: number }) {
  const { resetBoundary } = useErrorBoundary();
  const reset = () => {
    resetBoundary();
    window.location.href = route("dashboard");
    router.reload();
  };

  const title =
    {
      503: "503 Service Unavailable",
      504: "504 Timeout",
      500: "500 Server Error",
      404: "404 Page Not Found",
      403: "403 Forbidden",
    }[status] || "500 Server Error";

  const description =
    {
      503: "We're sorry, the server is currently unavailable. Please try again later.",
      504: "It took too long to respond to your request. Please try again later.",
      500: "Oops! Something went wrong on our end. Our engineers have been notified and are working on a fix. Please try again later or contact support for assistance.",
      404: "We couldn't find the page you were looking for.",
      403: "You don't have the correct permissions to access this page. Please contact your administrator for assistance.",
    }[status] ||
    "Oops! Something went wrong on our end. Our engineers have been notified and are working on a fix. Please try again later or contact support for assistance.";

  return (
    <section className="flex h-screen w-full flex-col items-center justify-center bg-gray-100 p-4 dark:bg-gray-900">
      <div className="space-y-6 lg:px-64">
        <div className="flex items-center gap-4 text-4xl font-bold text-gray-900 dark:text-gray-100">
          <Icon icon="fa-bug" className="h-10 w-10" />
          <div>
            <h1>{"That didn't look right."}</h1>
            <h4 className="text-xs">{title}</h4>
          </div>
        </div>
        <p className="text-pretty text-lg text-gray-600 dark:text-gray-300">
          {description}
        </p>
        <Button onClick={reset}>Return to dashboard</Button>
      </div>
    </section>
  );
}
